import React, { useState, useCallback, useEffect, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid

import NotesPopup from "./NotesPopup";

import "./index.css";
import {
  Card,
  Input,
  Checkbox,
  Button,
  Typography,
} from "@material-tailwind/react";
import Navbar from "./Navbar";

function renderState() {
  return "Uncontacted";
}

const url = "/api2/prospects/";

function App() {
  useEffect(() => {
    fetch(url, { redirect: "manual" }).then((response) => {
      //const totalCount = response.headers.get("X-Total-Count");
      return response.json().then((data) => {
        setRowData(data);
        return data;
      });
    });
  }, []);

  const [showNotes, setShowNotes] = useState(false);
  const [notes, setNotes] = useState("");
  const [rowData, setRowData] = useState([]);

  const openPopup = () => setShowNotes(true);
  const closePopup = () => setShowNotes(false);

  const CustomLinkedinCell = (props) => {
    var linkedId = props.getValue();
    var linkedUrl = "https://linkedin.com/in/" + linkedId + "/";
    return (
      <a href={linkedUrl} target="_new">
        {linkedId}
      </a>
    );
  };

  const CustomButtonComponent = (props) => {
    var notes = props.getValue();

    if (typeof notes !== "undefined") {
      if (notes.length > 0) {
        return <span>Notes</span>;
      } else {
        return <></>;
      }
    }
  };
  // Column Definitions: Defines the columns to be displayed.
  const [colDefs, setColDefs] = useState([
    { field: "row", sort: "desc", width: 70 },
    { field: "linkedinId", cellRenderer: CustomLinkedinCell, width: 250 },
    {
      field: "assignedRecruiter",
      filter: true,
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: ["hannes.bjurek", "mikael.backstrom"],
      },
      editable: true,
    },
    {
      field: "notes",
      editable: true,
      cellRenderer: CustomButtonComponent,
      width: 70,
      cellEditor: "agLargeTextCellEditor",
      cellEditorPopup: true,
    },
    { field: "contactDates", editable: true },
    { field: "firstName" },
    { field: "lastName" },
    { field: "currentEmployer" },
  ]);

  function notesRender(value) {
    if (value.length > 2) {
      return "N";
    } else {
      return "";
    }
  }

  function recruiterRender(value) {
    if (value === "hannes.bjurek") {
      return "Hannes";
    } else if (value === "mikael.backstrom") {
      return "Mikael";
    }
  }

  function datesRender(value) {
    if (value.length === 0) {
      return "";
    } else if (value.length === 1) {
      return value;
    } else if (value.length === 2) {
      return value[0] + ", " + value[1];
    }
  }

  const rowSelection = useMemo(() => {
    return {
      mode: "singleRow",
      checkboxes: false,
      enableClickSelection: true,
    };
  }, []);

  return (
    <>
      <Navbar />

      <NotesPopup showNotes={showNotes} notes={notes} onClose={closePopup} />

      <div
        className="ag-theme-quartz" // applying the Data Grid theme
        style={{}} // the Data Grid will fill the size of the parent container
      >
        <AgGridReact
          rowData={rowData}
          columnDefs={colDefs}
          rowSelection={rowSelection}
        />
      </div>
    </>
  );
}

export default App;
