import React, { useState, useCallback, useEffect } from "react";

export default function NotesPopup(props) {
  return (
    <>
      {props.showNotes ? (
        <>
          <div className="overlay">
            <div className="overlay-content">
              <h2>Notes</h2>
              <pre>{props.notes}</pre>
              <button onClick={props.onClose}>Close</button>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
